import React from 'react';

import Hero, { BackButton, Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';
import Leadgen from '../sections/leadgen';

const Papers = ({
  pageContext: {
    callToAction,
    subtitle,
    tagline,
    title,
    image: { gatsbyImageData: backgroundImage },
    document: { publicUrl: downloadUrl },
    text: { text },
    slug,
  },
  location: { pathname },
}) => {
  return (
    <Layout title={title} description={subtitle} pathname={pathname}>
      <Hero
        backgroundImage={backgroundImage}
        backgroundColor=""
        darkMode="true"
      >
        <BackButton />
        <Tagline>{tagline}</Tagline>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </Hero>
      <Leadgen
        text={text}
        title={title}
        slug={slug}
        callToAction={callToAction}
        downloadUrl={downloadUrl}
      />
    </Layout>
  );
};
export default Papers;
