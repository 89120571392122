import React from 'react';

import Grid from '@mui/material/Grid';
import LeadgenForm from '../../components/leadgenForm';
import Section from '../../components/section';
import SectionTitle from '../../components/sectionTitle';
import Markdown from '../../components/Markdown';

export default function Leadgen({
  title,
  centered,
  slug,
  downloadUrl,
  callToAction,
  text,
}) {
  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <SectionTitle title={title} centered={centered} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Markdown>{text}</Markdown>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LeadgenForm
            title={title}
            slug={slug}
            downloadUrl={downloadUrl}
            callToAction={callToAction}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
